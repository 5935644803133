<template>
  <v-theme-provider light id="consultdoc">
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="4"
      :key="kdoc"
    >
      <v-toolbar dense floating class="mt-n2 ml-n2" color="#73DDFF">
        <v-row>
          <v-col cols="12" sd="12" md="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="traiter_consult(8)"
                  v-if="
                    cloturer &&
                    editedItem.statut_id == '7' &&
                    offres_list.length > 0
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left> mdi-check-bold </v-icon>
                  Clotûrer
                </v-btn>
              </template>
              <span>Clotûrer cette consultation</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="traiter_consult(9)"
                  v-if="cloturer && editedItem.statut_id == '7'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left> mdi-cancel </v-icon>
                  Annuler
                </v-btn>
              </template>
              <span>Annuler cette consultation</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="valider_consult(7)"
                  v-if="
                    valider &&
                    editedItem.statut_id == '6' &&
                    detail_list.length > 0 &&
                    !edit
                  "
                  :disabled="tier_list.length == 0"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon left> mdi-check-bold </v-icon>
                  Valider
                </v-btn>
              </template>
              <span>Valider consultation</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="deleteConsult"
                  v-if="
                    editer &&
                    editedItem.statut_id == '6' &&
                    detail_list.length == 0 &&
                    tier_list.length == 0 &&
                    !edit
                  "
                  :disabled="docs.length > 0"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-delete </v-icon>
                  Supprimer
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="modifier"
                  v-if="editer && editedItem.statut_id == '6' && !edit"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon left> mdi-pencil </v-icon>
                  Modifier
                </v-btn>
              </template>
              <span>Editer pour modification</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="cancel"
                  v-if="editer && editedItem.statut_id == '6' && edit"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-close </v-icon> Annuler</v-btn
                >
              </template>
              <span>Annuler les Modifications</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="save"
                  v-if="editer && editedItem.statut_id == '6' && edit"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon left> mdi-content-save-outline </v-icon>
                  Enregistrer
                </v-btn>
              </template>
              <span>Enregistrer les modifications</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01061') ||
                      $store.state.isadmin)
                  "
                  @click="OpenDocList"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span>Documents Attachés</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="valider_consult(6)"
                  v-if="
                    ($store.state.auth.includes('01068') ||
                      $store.state.isadmin) &&
                    editedItem.statut_id == '7' &&
                    !edit
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-lock-open-variant </v-icon>
                </v-btn>
              </template>
              <span>Déverrouiller</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01060') ||
                      $store.state.isadmin)
                  "
                  @click.stop="drawer = !drawer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique Document</span>
            </v-tooltip>
          </v-col>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>
        </v-row>
      </v-toolbar>

      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="4" md="4" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col cols="12" sd="8" md="8">
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
            </v-col>
            <!-- <v-col
            cols="12"
            sd="9"
            md="9"
            align-self="center"
            class="text-center"
          >
            <h1>{{ myname }}</h1>
          </v-col> -->
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="2" md="12" lg="12">
                <v-row>
                  <v-col cols="12" sd="12" md="12">
                    <h2>{{ formTitle }}</h2>
                    <br />
                    <strong> Date : {{ datefr(editedItem.date_cons) }}</strong>
                    <br />
                  </v-col>
                  <v-col cols="12" sd="12" md="12" v-if="!edit">
                    Ref. : {{ editedItem.ref_cons }}
                  </v-col>
                  <v-col cols="12" sd="12" md="12" v-if="!edit">
                    Libelle : {{ editedItem.label }}
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="4">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.ref_cons"
                          label="Reférence"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field
                          dense
                          v-model="editedItem.label"
                          label="Libelle"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="6" md="3">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          class="inputPrice"
                          v-model.number="editedItem.delai"
                          :label="'Délai (Jours)'"
                          :readonly="!edit"
                          @input="addDays"
                          hide-spin-buttons
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <v-tabs v-model="tabs">
                <v-tab href="#1" key="1"> Produits </v-tab>
                <v-tab href="#2" key="2"> Fournisseurs </v-tab>
                <v-tab href="#3" key="3" v-if="editedItem.statut_id > '6'">
                  Offres
                </v-tab>
                <v-tab href="#4" key="4" v-if="editedItem.statut_id > '6'">
                  TCO
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabs">
                <v-tab-item :value="'1'">
                  <listitems
                    :list="detail_list"
                    :title="'Liste des produits'"
                    :headers="products_headers"
                    :filename="'Produits'"
                    :sheetname="'Produits'"
                    @open="OpenProductForm"
                    @btn_click="OpenProductForm"
                    :master="true"
                    :showedit="false"
                    :key="klist"
                    :add="
                      editer && editedItem.statut_id == '6' && editedItem.id > 0
                    "
                    :Update="
                      editer && editedItem.statut_id == '6' && editedItem.id > 0
                    "
                    :del="
                      editer && editedItem.statut_id == '6' && editedItem.id > 0
                    "
                    :elevation="0"
                    @delete="deleteitem"
                  >
                  </listitems>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <listitems
                    :list="tier_list"
                    :qDelete="Qtierdelete"
                    :title="'Fournisseurs Consultés'"
                    :headers="tiers_headers"
                    :filename="'Fournisseurs'"
                    :sheetname="'Fournisseurs'"
                    :master="false"
                    :showstd="false"
                    :showedit="false"
                    :key="klist_tier"
                    @open="OpenTierForm"
                    :print="editedItem.statut_id >= '7'"
                    :exp_excel="false"
                    :add="
                      editer && editedItem.statut_id == '6' && editedItem.id > 0
                    "
                    :Update="
                      editer && editedItem.statut_id <= '7' && editedItem.id > 0
                    "
                    :del="
                      editer && editedItem.statut_id == '6' && editedItem.id > 0
                    "
                    :elevation="0"
                    @add="tier_add"
                    @rowselect="tier_select"
                    @print="print_consult"
                  >
                  </listitems>
                </v-tab-item>
                <v-tab-item :value="'3'">
                  <listitems
                    :list="offres_list"
                    :title="'Liste des Offres'"
                    :headers="offres_headers"
                    :filename="'Offres'"
                    :sheetname="'Offres'"
                    :master="false"
                    :showstd="false"
                    :key="klist_offre"
                    :exp_excel="false"
                    :add="false"
                    :Update="false"
                    :del="false"
                    :elevation="0"
                  >
                  </listitems>
                </v-tab-item>
                <v-tab-item :value="'4'">
                  <listitems
                    :list="tco_list"
                    :title="'Tableau comparatif'"
                    :headers="tco_headers"
                    :secondligne="tco2_headers"
                    :filename="'TCO'"
                    :sheetname="'TCO'"
                    :master="false"
                    :print="true"
                    :showstd="false"
                    :key="klist_tco"
                    :exp_excel="true"
                    :add="false"
                    :Update="false"
                    :del="false"
                    :elevation="0"
                    @print="print_tco"
                  >
                  </listitems>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit">
            <v-col cols="12" sd="6" md="7">
              <v-row no-gutters>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  DELAI

                  {{
                    editedItem.delai
                      ? editedItem.delai +
                        " jours (" +
                        datefr(editedItem.date_limite) +
                        ")"
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            centered
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <consultdetailform
            :item="product"
            :items="detail_list"
            :readonly="editedItem.statut_id != '6'"
            :key="dkey"
            :showForm="!isDetailClosed"
            @close="closeDetailForm"
            :consult="editedItem"
            :dem_products="dem_products"
          >
          </consultdetailform>
          <consulttierform
            :item="tier"
            :items="tier_list"
            :readonly="editedItem.statut_id != '6'"
            :key="tkey"
            :showForm="!isTierClosed"
            @close="closeTierForm"
          >
          </consulttierform>
          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="13"
            :auth="'01070'"
          >
          </filelist>
          <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            right
            width="600"
          >
            <v-card flat>
              <v-card-title>
                <v-toolbar color="grey lighten-2" flat>
                  <v-toolbar-title> Historique du Document </v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="(item, i) in operations"
                    :key="i"
                    fill-dot
                    small
                    :color="item.color"
                    :icon="item.icon"
                  >
                    <strong>{{ item.label }}</strong>
                    {{
                      " Par " +
                      item.user_name +
                      " le " +
                      datefr(item.created_at, true)
                    }}
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-navigation-drawer>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
import DELETE_CONSULTATION_DETAIL from "../graphql/Consult/DELETE_CONSULTATION_DETAIL.gql";
import DELETE_CONSULTATION_TIER from "../graphql/Consult/DELETE_CONSULTATION_TIER.gql";
import DELETE_CONSULTATION from "../graphql/Consult/DELETE_CONSULTATION.gql";
import CREATE_CONSULTATION from "../graphql/Consult/CREATE_CONSULTATION.gql";
import UPDATE_CONSULTATION from "../graphql/Consult/UPDATE_CONSULTATION.gql";
import UPDATE_OFFRE from "../graphql/Proforma/UPDATE_OFFRE.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";

import { addHeaders, addFooters } from "print/entete.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { myLogo, myName, myIF, myRC, myART, grt } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    consultdetailform: () => import("./ConsultDetailForm.vue"),
    consulttierform: () => import("./ConsultTierForm.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
  },
  props: {
    consult: Object,
    editer: Boolean,
    valider: Boolean,
    cloturer: Boolean,
    dem_products: Array,
    edition: { type: Boolean, default: false },
  },
  data() {
    return {
      tabs: "0",
      message: "",
      alert: false,
      type_alert: "error",
      datepicker2: false,
      drawer: null,
      modify: false,
      progress: false,
      duedate: null,
      valid: true,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isTierClosed: true,
      isProdClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",
      is_motif: false,
      mc: 200,
      dkey: 100,
      tkey: 400,
      pkey: 300,
      fdocl: 1000,
      tier: {},
      consults_list: [],
      tco_list: [],
      isOrderClosed: true,
      order: {},
      changed: false,
      korder: 0,
      product: {},
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "N° DA",
          value: "no_da",
          selected: true,
          align: "left",
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
        },
        {
          text: "Produit",
          value: "label",
          slot: "href",
          column: "produit_id",
          selected: true,
          align: "left",
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          width: "120px",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
        {
          text: "",
          slot: "actions",
          selected: true,
          column: "produit_id",
          hiden: true,
        },
      ],
      tiers_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code_fournisseur",
          selected: true,
          align: "left",
        },
        {
          text: "Fournisseur",
          value: "tier_name",
          slot: "href",
          selected: true,
          align: "left",
        },
        {
          text: "Date Consultation",
          value: "date_const",
          slot: "date",
          selected: true,
          align: "left",
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
      ],
      offres_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code_tier",
          selected: true,
          align: "left",
        },

        {
          text: "Fournisseur",
          value: "tier_name",
          selected: true,
          align: "left",
        },
        {
          text: "Date Offre",
          value: "date_pfm",
          slot: "date",
          selected: true,
          align: "left",
        },
        {
          text: "Ref.",
          value: "nopfm",
          selected: true,
        },
      ],
      tco_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
        },
        {
          text: "Produit",
          value: "label",
          selected: true,
          width: "200px",
          align: "left",
        },
      ],
      tco2_headers: [
        {
          text: "",
          value: "no",
          selected: true,
          align: "center",
          colspan: 1,
          width: 10,
        },
        {
          text: "",
          value: "code",
          selected: true,
          colspan: 1,
          align: "left",
        },
        {
          text: "",
          value: "label",
          selected: true,
          width: "200px",
          colspan: 1,
          align: "left",
        },
      ],
      edit: false,
      editedItem: {},
      consult_list: [],
      kdoc: 700,
      klist: 500,
      klist_tier: 600,
      klist_offre: 800,
      klist_tco: 900,
      cmpt: 0,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        statut_id: "6",
        details: [],
        docs: [],
        offres: [],
        tiers: [],
        date_cons: null,
      },
    };
  },
  watch: {},
  created() {
    if (this.consult.id) {
      this.edit = this.edition;
      if (this.consult.id > 0) {
        this.editedItem = Object.assign({}, this.consult);
        if (this.editedItem.statut_id == "9") {
          this.message =
            "Motif : " + (this.editedItem.motif ? this.editedItem.motif : "");
          this.alert = true;
        }
        this.addDays();
        this.calc_tco();
      } else {
        this.defaultItem.date_cons = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }

    if (this.editedItem.statut_id != "6") {
      this.products_headers[3].slot = null;
    }
    if (this.editedItem.statut_id > "7") {
      this.tiers_headers[2].slot = null;
    }
    this.modify = this.editer && this.editedItem.statut_id == "6";
  },
  methods: {
    calc_tco() {
      this.detail_list.forEach((element) => {
        this.tco_list.push(element);
      });
      //headers complete
      if (this.tier_list)
        this.tier_list.forEach((element) => {
          this.tco_headers.push({
            text: "Qte ",
            value: "qte_" + element.code_fournisseur,
            slot: "cur",
            align: "end",
            width: "120px",
            selected: true,
          });
          this.tco_headers.push({
            text: "PU ",
            value: "pu_" + element.code_fournisseur,
            slot: "cur",
            align: "end",
            width: "120px",
            selected: true,
          });
          this.tco_headers.push({
            text: "Délai ",
            value: "delai_" + element.code_fournisseur,
            align: "center",
            width: "100px",
            selected: true,
          });
          this.tco2_headers.push({
            text: element.tier_name,
            value: element.code_fournisseur,
            align: "center",
            colspan: 3,
            selected: true,
          });
        });
      //data complete
      if (this.offres_list)
        this.offres_list.forEach((element) => {
          let d = element.pfmdetails;
          for (let index = 0; index < d.length; index++) {
            const elm = d[index];
            let i = this.tco_list.findIndex(
              (elm2) => elm2.produit_id == elm.produit_id
            );
            if (i >= 0) {
              this.tco_list[i]["qte_" + element.code_tier] = elm.qte;
              this.tco_list[i]["pu_" + element.code_tier] = elm.pu;
              this.tco_list[i]["delai_" + element.code_tier] = elm.delai;
            }
          }
        });
      // //price compare
      // this.tco_list.forEach((element) => {
      //   let pu_min = -1;
      //   element.cloture = true;
      //   let code_tier = null;
      //   this.offres_list.forEach((element2) => {
      //     if (element["pu_" + element2.code_tier] > 0 && pu_min == -1) {
      //       pu_min = element["pu_" + element2.code_tier];
      //       code_tier = element2.code_tier;
      //     } else if (
      //       element["pu_" + element2.code_tier] > 0 &&
      //       element["pu_" + element2.code_tier] <= pu_min &&
      //       pu_min != -1
      //     ) {
      //       pu_min = element["pu_" + element2.code_tier];
      //       code_tier = element2.code_tier;
      //     }
      //   });
      //   let i = this.tco_headers.findIndex(
      //     (elm3) => elm3.value == "pu_" + code_tier
      //   );
      //   if (i >= 0) this.tco_headers[i].cloture = true;
      // });
      this.klist_tco++;
    },
    async print_tco() {
      let allitems = this.tco_list;

      let head = [];
      let columns = [];
      let items = [];
      let foot = [];
      let item;
      //data formating

      for (let index = 0; index < allitems.length; index++) {
        item = {};
        this.tco_headers.forEach((elm) => {
          if (elm.value == "no") {
            item[elm.value] = index + 1;
          }
          if (elm.slot == "cur22") {
            item[elm.value] = this.numberWithSpace(
              parseFloat(allitems[index][elm.value]).toFixed(2)
            );
          } else if (elm.slot == "cur" && allitems[index][elm.value]) {
            item[elm.value] = this.numberWithSpace(
              parseFloat(allitems[index][elm.value]).toFixed(2)
            );
          } else if (allitems[index][elm.value])
            item[elm.value] = allitems[index][elm.value];
        });
        items.push(item);
      }

      let columnStyles = {};
      var doc = new jsPDF("l");
      item = {};
      let h1 = [];
      this.tco2_headers.forEach((element) => {
        h1.push({
          content: element.text,
          colSpan: element.colspan,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });
      });

      let h2 = [];
      this.tco_headers.forEach((element) => {
        h2.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });

        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
          cellWidth: element.slot == "cur" ? 28 : "auto",
          fontSize: 9,
        };
      });
      head.push(h1);
      head.push(h2);
      doc.setFontSize(18);
      doc.setFont("helvetica", "normal");
      doc.text("Tableau Comparatif des Offres", 100, 50);
      doc.autoTable({
        margin: { top: 60 },
        headStyles: {
          valign: "middle",
          fillColor: [128, 128, 128],
          fontSize: 9,
        },
        columnStyles: columnStyles,
        head: head,
        body: items,
        columns: columns,
        foot: foot,
        footStyles: {
          halign: "right",
          fillColor: [128, 128, 128],
          fontSize: 9,
        },
        showHead: "everyPage",
        showFoot: "lastPage",
      });
      addHeaders(doc, this.$store);
      addFooters(doc, this.$store);

      doc.setProperties({ title: "TCO" });
      doc.output("pdfobjectnewwindow");
    },
    tier_select(item) {
      this.tier = item;
    },
    print_consult() {
      let doctype = [];
      if (this.tier.date_const) {
        doctype.push({
          lib: {
            content: "Demande d'offre",
            styles: {
              fontStyle: "bold",
              fontSize: 20,
              textColor: [0, 0, 0],
              cellPadding: { bottom: 3 },
            },
            colSpan: 2,
          },
        });
        doctype.push({
          lib: {
            content: "N° :",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
          val: {
            content: this.editedItem.no_cons,
            styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Date : ",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
          val: {
            content: datefr(this.editedItem.date_cons),
            styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Réference:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.ref_cons ? this.editedItem.ref_cons : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        if (this.editedItem.delai)
          doctype.push({
            lib: {
              content: "Délai :",
              styles: { fontStyle: "normal", fontSize: 9 },
            },
            val: {
              content: this.editedItem.delai
                ? this.editedItem.delai + " Jours"
                : "",
              styles: {
                fontStyle: "normal",
                fontSize: 9,
                textColor: [0, 0, 0],
              },
            },
          });

        doctype.push({
          lib: {
            content: "Contact:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.contact ? this.editedItem.contact : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        let tier = [];

        tier.push({
          lib: {
            content: this.tier.tier_name ? this.tier.tier_name : "",
            styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: this.tier.adresse ? this.tier.adresse : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: "Tél :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.tier.tel ? this.tier.tel : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "Email :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.tier.email ? this.tier.email : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        let foot = [];
        let detailheaders = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Ref.",
            value: "code",
            selected: true,
            align: "left",
            width: 20,
          },
          {
            text: "Désignation",
            value: "label",
            selected: true,
            align: "left",
            width: "auto",
          },
          {
            text: "Quantité",
            value: "qte",
            slot: "qte",
            width: 30,
            align: "end",
            selected: true,
          },
          {
            text: "Unité",
            value: "unit",
            selected: true,
          },
          {
            text: "PU HT",
            value: "pu",
            width: 25,
            align: "end",
            selected: true,
          },
          {
            text: "Délai",
            value: "delai",
            slot: "text",
            align: "center",
            width: 30,
            selected: true,
          },
        ];
        let lettre = [];
        let t =
          "Pour une éventuelle commande, prière nous transmettre par retour fax ou email votre meilleure offre de :";
        doc_template(
          this.$store,
          detailheaders,
          this.detail_list,
          doctype,
          tier,
          foot,
          lettre,
          this.editedItem.notes,
          "Consultation",
          null,
          null,
          null,
          t
        );
      } else {
        this.snackbar_text = "Veuillez saisir la date de la consultation";
        this.snackbar_color = "error";
        this.snackbar = true;
      }
    },
    tier_add() {
      this.$store.dispatch("Changed", true);
    },
    async modifier() {
      if (await this.verif_modif()) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async cancel() {
      let ok = true;
      if (
        (this.cmpt > 0 && this.editedItem.id < 0) ||
        (this.cmpt > 1 && this.editedItem.id > 0)
      ) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.editedItem.id < 0) {
          this.$emit("cancel", this.editedItem);
          this.editedItem.id = null;
        }
        this.edit = false;
        this.$emit("edition", false);
        this.kdoc++;
        this.$store.dispatch("Changed", true);
      }
    },
    async verif_modif() {
      this.alert = false;

      let ok = false;
      let v = {
        table: "consultations",
        statut_id: "6",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2) {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    async deleteitem(item) {
      if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimmer cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          let r = await this.maj(DELETE_CONSULTATION_DETAIL, { id: item.id });
          if (r) {
            this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.$store.dispatch("Editing", false);
            this.update_modif();
            this.kdoc++;
          }
        } else this.update_modif();
      }
    },

    nbjour() {
      this.datepicker2 = false;
      var date2 = new Date(this.duedate);
      var date1 = new Date(this.editedItem.date_cons);
      var diff = date2.getTime() - date1.getTime();
      this.editedItem.delai = Math.floor(diff / (24 * 3600 * 1000));
      this.cmpt++;
    },
    addDays() {
      if (this.editedItem.delai) {
        let d = parseInt(this.editedItem.delai);
        var result = new Date(this.editedItem.date_cons);
        result.setDate(result.getDate() + d);
        this.duedate = result.toISOString().substr(0, 10);
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async OpenProductForm(item) {
      if (await this.verif_modif()) {
        this.product = item;
        this.product.cons_id = this.editedItem.id;
        this.dkey++;
        this.isDetailClosed = false;
      }
    },
    async OpenTierForm(item) {
      if (await this.verif_modif()) {
        this.tier = item;
        this.tier.cons_id = this.editedItem.id;
        this.tkey++;
        this.isTierClosed = false;
      }
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },

    async traiter_consult(s) {
      this.is_motif = s == 9;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (s == 8 ? "cloturer" : "annuler") +
            " cette consultation?",
          { color: "orange darken-3" }
        )
      ) {
        this.editedItem.statut_id = s;
        let v = {
          consultation: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
            motif: this.editedItem.motif,
            modif_by: null,
            modif_date: null,
            write_uid: this.$store.state.me.id,
          },
        };
        let r = await this.maj(UPDATE_CONSULTATION, v, true);
        if (r && s == 8) {
          v = {
            cons_id: this.editedItem.id,
            statut_id: 3,
            motif: "Cloture de la consultation",
            write_uid: this.$store.state.me.id,
          };
          this.maj(UPDATE_OFFRE, v);
        }

        this.is_motif = false;
      }
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async valider_consult(statut) {
      let ok = true;

      // if (
      //   statut == 7 &&
      //   this.detail_list.find((elm) => elm.produit_id == null)
      // ) {
      //   this.message =
      //     "Un nouveau produit doit être créer!!-validation impossible";
      //   this.type_alert = "error";
      //   this.alert = true;
      //   ok = false;
      // }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir " +
              (statut == 7 ? "valider" : "déverrouiller") +
              " cette consultation?"
          )
        ) {
          this.editedItem.statut_id = statut;
          if (statut == 6) {
            let v = {
              consultation: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                modif_by: null,
                modif_date: null,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_CONSULTATION, v, true);
          }
          if (statut == 7) {
            let v = {
              consultation: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                date_cons: this.editedItem.date_cons,
                label: this.editedItem.label,
                write_uid: this.$store.state.me.id,
                comment: this.editedItem.comment,
                ref_cons: this.editedItem.ref_cons,
                destination: this.editedItem.destination,
                modif_by: null,
                modif_date: null,
                delai: parseInt(this.editedItem.delai),
              },
            };
            await this.maj(UPDATE_CONSULTATION, v, true);
          }
        }
      }
    },
    closeDetailForm() {
      this.isDetailClosed = true;
    },
    closeTierForm() {
      this.isTierClosed = true;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async deleteConsult() {
      if (this.editedItem.id < 0) {
        this.editedItem.id = null;
        this.$emit("cancel", this.editedItem);
      } else if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cette consultation?"
          )
        ) {
          let v = {
            id: this.editedItem.id,
          };
          let r = await this.requette(DELETE_CONSULTATION, v);
          if (r) {
            this.editedItem = { id: null };
            this.$store.dispatch("Changed", true);
          }
        } else this.update_modif();
      }
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          consultation: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_CONSULTATION, v);
      }
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },

    sumtaxTotal(key) {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce((a, b) => a + (b[key] || 0), 0)
        : 0;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;

        if (ok) {
          if (this.editedItem.id > 0) {
            let v = {
              consultation: {
                id: this.editedItem.id,
                label: this.editedItem.label,
                date_cons: this.editedItem.date_cons,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                ref_cons: this.editedItem.ref_cons,
                delai: parseInt(this.editedItem.delai),
                modif_by: null,
                modif_date: null,
                write_uid: this.$store.state.me.id,
              },
            };
            this.maj(UPDATE_CONSULTATION, v, true);
          } else {
            let v = {
              consultation: {
                label: this.editedItem.label,
                date_cons: this.$store.state.today,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                ref_cons: this.editedItem.ref_cons,
                delai: parseInt(this.editedItem.delai),
                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            };

            let r = await this.maj(CREATE_CONSULTATION, v);
            if (r) {
              this.editedItem.id = r.createConsultation.id;
              this.$store.dispatch("Changed", true);
              this.$emit("change", this.editedItem);
            }
          }
          this.edit = false;
          this.$emit("edition", false);
          this.kdoc++;
        }
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedItem.id < 0 || !this.editedItem.no_cons
        ? "Consultation d'Achat"
        : "Consultation d'Achat N° " + this.editedItem.no_cons;
    },
    garantie() {
      return grt ? true : false;
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },

    Qdelete() {
      return DELETE_CONSULTATION_DETAIL;
    },

    Qtierdelete() {
      return DELETE_CONSULTATION_TIER;
    },
    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.operations ? this.editedItem.operations : [];
    },

    detail_list: function () {
      let l = [];
      if (this.editedItem.details) l = this.editedItem.details;
      return l;
    },
    tier_list: function () {
      let l = [];
      if (this.editedItem.tiers) l = this.editedItem.tiers;
      return l;
    },
    docs: function () {
      let l = [];
      if (this.editedItem.docs) l = this.editedItem.docs;
      return l;
    },
    offres_list: function () {
      let l = [];
      if (this.editedItem.offres) l = this.editedItem.offres;
      return l;
    },
    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return accumulator + item.pu * item.qte;
          }, 0)
        : 0;
      return total;
    },

    taxTotal: function () {
      let taxs = [];
      if (
        this.detail_list &&
        (this.editedItem.exe_tva != "1" || this.exe_tva) &&
        this.editedItem.currency_id == 114
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0)
              taxs[i].tva =
                taxs[i].tva + (element.pu * element.qte * element.tva_tx) / 100;
            else
              taxs.push({
                taux: element.tva_tx,
                tva: (element.pu * element.qte * element.tva_tx) / 100,
              });
          }
        });
      }
      return taxs;
    },

    ttc: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs = taxs + element.tva;
      });

      var total = this.subTotal + taxs + this.editedItem.mont_transport;
      return total;
    },
    mont_exe_taxs: function () {
      let taxs = 0;
      if (
        this.detail_list &&
        this.exe_tva &&
        this.editedItem.currency_id == 114
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            taxs = taxs + (element.pu * element.qte * element.tva_tx) / 100;
          }
        });
      }
      return taxs;
    },
    grandTotal: function () {
      var total = this.ttc - this.mont_exe_taxs;
      return total;
    },
  },
};
</script>
